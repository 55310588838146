<template>
  <div class="row">
    <div class="col-12 col-lg-4 form-group">
      <label>E-mail <sup>Required</sup></label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <i>
              <font-awesome-icon :icon="['fas', 'envelope']" />
            </i>
          </span>
        </div>
        <input
          type="text"
          :class="['form-control form-control-lg', { 'is-invalid': $v.document.email.$error }]"
          :disabled="isDisabled"
          :readonly="isReadOnly"

          v-model.trim="$v.document.email.$model"
        >
      </div>
    </div>

    <div class="col-12 col-lg-4 form-group">
      <label>Firstname</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <i>
              <font-awesome-icon :icon="['fas', 'user']" />
            </i>
          </span>
        </div>
        <input
          type="text"
          :class="['form-control form-control-lg', { 'is-invalid': $v.document.firstname.$error }]"
          :disabled="isDisabled"
          :readonly="isReadOnly"

          v-model.trim="$v.document.firstname.$model"
        >
      </div>
    </div>

    <div class="col-12 col-lg-4 form-group">
      <label>Lastname</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <i>
              <font-awesome-icon :icon="['fas', 'user']" />
            </i>
          </span>
        </div>
        <input
          type="text"
          :class="['form-control form-control-lg', { 'is-invalid': $v.document.lastname.$error }]"
          :disabled="isDisabled"
          :readonly="isReadOnly"

          v-model.trim="$v.document.lastname.$model"
        >
      </div>
    </div>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import Form from '@/mixins/form';
import User from '@/libs/classes/user';

export default {
  mixins: [Form],
  props: {
    document: {
      type: User,
      default: null,
    },
  },

  validations: {
    document: {
      firstname: {},
      lastname: {},
      email: {
        required,
        email,
      },
    },
  },
};
</script>
