export default {
  props: {
    action: {
      type: String,
      default: 'VIEW',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      isMounted: false,
      isLoading: false,
      isFormLoading: false,
    };
  },
  computed: {
    isDisabled () {
      return this.disabled === true || this.isFormLoading === true;
    },
    isReadOnly () {
      return this.action === 'VIEW';
    },
  },

  mounted () {
    this.$nextTick(() => {
      this.isMounted = true;
    });
  },
};
